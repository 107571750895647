.create-email-template__wrapper {
  justify-content: center;
  background-color: $light-grey;
  padding-top: 35px;
  height:100%;

  .ant-tabs {
    &-card .ant-tabs-card-bar .ant-tabs-tab-active {
      background: $light-grey;
  }
    &-bar {
      margin-bottom: 0;
      border-bottom: 0 !important;
    }

    &-tab {
      padding: 1rem 2rem !important;

      &-active {
        border-color: $form-border-grey !important;
        border-bottom: 0 !important;
      }

      .ant-switch {
        margin-left: 0.75rem;
      }

      &-prev,
      &-next {
        border-bottom: 1px solid $form-border-grey;
      }
    }
  }

  .template-form {
    padding: 1.5rem 2rem;
    background: #fff;
    border: 1px solid $form-border-grey;
    border-top: 0;

    .toolbar {
      border: 0px;
      padding: 0;

      .rdw-option-wrapper {
        padding: 15px;
      }

      .rdw-inline-wrapper {
        .rdw-option-wrapper {
          &:frist-child {
            margin-left: 0;
          }
        }
      }
    }

    .editor {
      min-height: 300px;
      border: 1px solid $form-border-grey;
      border-radius: 2px;
      padding: 10px;
    }
    .scheduled-email-select-list {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 5px;
    }
  }
  .email-link-popup {
    button {
      width: 100px;
    }
  }
  
  .message-wrapper {
    width: 100%;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 0;
    padding: 1.5rem 2rem;
    min-height: 300px;
  }
  .message-card {
    padding: 20px;
    border: 1px solid #d9d9d9;
    min-height: 800px;
  }
}
