#root > div > section > section > main {
  @media screen and (max-width: 789px) {
    padding: 20px 10px;
  }
}
.events {
  &__right {
    display: flex;
    align-items: center;

    .anticon {
      color: #1890ff;
    }

    & > .anticon {
      font-size: 1.75em;
    }

    & > * > .anticon {
      margin-left: 15px;
    }
  }

  .ant {
    &-empty {
      padding: 5% 0;

      img {
        max-width: 100%;
      }
    }

    &-collapse {
      background-color: transparent;
    }

    &-layout {
      &-sider {
        max-width: 50px !important;
        min-width: 50px !important;
        background-color: white !important;
        padding-left: 10px !important;

        &-light {
          max-width: 50px !important;
          min-width: 50px !important;
          background-color: white !important;
          padding-left: 10px !important;
        }
      }
    }

    &-btn-circle {
      @media (max-width: 408px) {
        margin-left: 0;
      }
    }

    &-pagination {
      margin-right: 20px !important;
    }

    &-table {
      .ant-table-content {
        overflow: auto;
      }

      &-row {
        cursor: pointer;
      }
    }
  }
  &-container {
    .ant-card-body {
      padding: 0px;
    }
  }
  .table-column-icon {
    padding: 0 10px;
    width: 30px;
  }
  .table-column-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .ant-tag {
      margin-bottom: 5px;
      border-radius: 20px;
      display: flex;
      padding-top: 3px;
      padding-bottom: 3px;
      align-items: center;
      justify-content: center;
      .tag-point {
        height: 10px;
        width: 10px;
        margin-right: 5px;
        border-radius: 10px;
        opacity: 0.6;

        &.red {
          background-color: red;
        }

        &.cyan {
          background-color: cyan;
        }

        &.blue {
          background-color: blue;
        }

        &.green {
          background-color: green;
        }
      }
    }
    &__date {
      @media screen and (max-width: 789px) {
        display: none;
      }
    }
  }
  .action-button__wrapper {
    @media screen and (max-width: 789px) {
      .ant-btn {
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
        font-size: 14px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ant-picker.ant-picker-range {
    margin-right: 1em;
  }

  &__filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .download-btn {
      color: $primary;
      margin-right: 15px !important;
    }

    & > div {
      display: flex;

      .ant-input-search {
        max-width: 300px;
        width: 100%;
        margin-bottom: 0.5em;
      }
    }
    & > * {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .anticon-sync {
    font-size: 1.65em;
    align-self: center;
  }

  @include breakpoint(max_575) {
    .csv-download__wrapper {
      display: none;
    }
  }
}

.site-page-header .ant-page-header-heading-title {
  cursor: pointer;
  font-weight: 500;
  vertical-align: middle;

  span.ant-tag {
    margin-left: 1em !important;
    transform: translateY(-2px) !important;
  }
}

.event-summary-modal {
  .ant-drawer-content-wrapper {
    width: 720px !important;

    @include breakpoint(max_sm) {
      width: 95% !important;
    }
  }

  h4.ant-typography {
    font-size: 1em;
  }
}

.info-card-text {
  width: 20rem;
  background-color: #fff;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.info-icon {
  font-size: 0.7rem;
  color: black;
  margin: -6px 0px 0px 5px;
}

.notes {
  &__list {
    .ant-list-items {
      max-height: 400px;
      overflow: auto;
    }

    .ant-list-empty-text {
      display: none;
    }
  }

  .sb-avatar__text span {
    font-size: 14.3118px !important;
  }
}

.btn-success {
  background-color: #7ad8a0 !important;
  border: #7ad8a0 !important;
}

.booking-note {
  display: flex;
  justify-content: space-between;

  .edit-or-delete-note {
    position: absolute;
    top: -7px;
    right: 15px;
    
    .ant-btn {
      padding: 0;
    }
  }
}

.filter-icon {
  font-size: 16;
  color: '#3DA0F2' !important;
}

.event-name {
  text-transform: 'capitalize';
  font-weight: 600;
}
