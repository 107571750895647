.job-builder-container {
  width: 100%;
  height: 100%;
  display: block;
  padding-top: '40px';
  overflow-y: scroll;
   .job-builder{
    width: 100%;
    margin:40px;
   }
}
