.login {
    .ant-alert {
        margin-top: 30px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    p,
    ul {
        margin: 0;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1em;
    }

    a {
        transition: all 0.3s ease-in-out;
    }

    a,
    a:active,
    a:focus,
    a:hover,
    html p a:hover {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }

    .bp-max-768,
    .bp-max-768-flex,
    .bp-min-769-flex {
        display: none !important;
    }

    @media screen and (min-width: 769px) {
        .bp-min-769-flex {
            display: flex !important;
        }
    }

    @media screen and (max-width: 768px) {
        .bp-max-768 {
            display: block !important;
        }
        .bp-max-768-flex {
            display: flex !important;
        }
    }

    a:focus {
        outline: 0 !important;
    }

    :focus {
        outline: 0;
    }

    ul li {
        display: block;
    }

    h3 {
        font-family: Muli, sans-serif;
        font-weight: 300;
        color: $primary-text-color;
    }

    h3 {
        font-size: 22px;
        line-height: 1.35;
        letter-spacing: 0;
    }

    @media (min-width: 576px) {
        h3 {
            font-size: 24px;
        }
    }

    @media (min-width: 768px) {
        h3 {
            font-size: 30px;
        }
    }

    @media (min-width: 1200px) {
        h3 {
            font-size: 39px;
            line-height: 1.23077;
        }
    }

    ::selection {
        background: rgba(38, 47, 60, 0.5);
        color: #fff;
    }

    ::-moz-selection {
        background: rgba(38, 47, 60, 0.5);
        color: #fff;
    }

    .text-1 {
        color: $primary-text-color;
        letter-spacing: 0;
    }

    .text-1 > * {
        margin-right: 0.2em;
    }

    ul.horizontal-list-of-logos {
        display: flex;
        align-items: center;
    }

    @media (max-width: 991.98px) {
        ul.horizontal-list-of-logos {
            margin: 15px 0 0;
        }
    }

    ul.horizontal-list-of-logos li {
        margin-right: 35px;
    }

    ul.horizontal-list-of-logos li img {
        height: 25px;
    }

    @media (max-width: 991.98px) {
        ul.horizontal-list-of-logos li {
            margin-right: 20px;
        }
        ul.horizontal-list-of-logos li img {
            height: 22px;
        }
    }

    ul.horizontal-list-of-logos li:last-child {
        margin-right: 0;
    }

    a,
    a:active,
    a:focus {
        color: inherit;
    }

    a:hover {
        color: #1e266d;
    }

    .quote-with-image {
        display: flex;
    }

    .quote-with-image .image-circle {
        margin-right: 30px;
        width: 85px;
        height: 85px;
        box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
        border-radius: 1000px;
        min-width: 85px;
        overflow: hidden;
    }

    .quote-with-image .image-circle img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .quote-with-image q {
        margin-bottom: 0.5em;
    }

    .ant-input-search {
        margin-top: 1em;
    }

    .gmail-is-my-main-button {
        margin: 1em 0 0;
        padding: 0;
    }

    @media (max-width: 991.98px) {
        .quote-with-image {
            display: block;
        }
        .quote-with-image .image-circle {
            margin-bottom: 1em;
        }
    }

    .box-shadow-1 {
        margin-left: auto;
        margin-right: auto;
    }

    .box-shadow-1 {
        background: #fff;
        max-width: 585px;
        padding: 35px;
        border-radius: 6px;
        box-shadow: 1px 6px 25px 0 rgba(75, 81, 91, 0.5);
    }

    @supports (-webkit-background-clip: text) {
    }

    img {
        display: inline-block;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .border-button {
        cursor: pointer;
        position: relative;
        padding: 10px 20px;
        border-radius: 5px;
        display: block;
        margin-top: 0.75em;

        .anticon {
            font-size: 1.25em;
            margin-right: 0.25em;
        }
    }

    .border-button.gray {
        border: 1px solid #d7d7d7;
    }

    .border-button.gray:hover {
        background: #f9f8f8;
    }

    .border-button.google-g {
        padding: 10px 20px 10px 50px;
    }

    .border-button.google-g::before {
        content: " ";
        position: absolute;
        background: url("/dashboard/login/google-g.png");
        background-size: 100%;
        width: 20px;
        height: 20px;
        left: 17px;
        top: 11px;
    }

    #form-output-global {
        position: fixed;
        bottom: 30px;
        left: 15px;
        z-index: 2000;
        visibility: hidden;
        transform: translate3d(-500px, 0, 0);
        transition: 0.3s all ease;
    }

    @media (min-width: 576px) {
        #form-output-global {
            left: 30px;
        }
    }

    @supports (filter: drop-shadow(0 0 0 #000)) {
    }

    .snackbars {
        color: #fff;
    }

    .snackbars {
        padding: 9px 16px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        background-color: $primary-text-color;
        border-radius: 0;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
    }

    @media (min-width: 576px) {
        .snackbars {
            max-width: 540px;
            padding: 12px 15px;
            font-size: 15px;
        }
    }

    .layout-2 {
        padding: 30px 15px;
        margin-left: auto;
        margin-right: auto;
        z-index: 999;
    }

    .layout-2-inner {
        display: inline-table;
        margin-bottom: -15px;
        margin-left: -15px;
        text-align: center;
    }

    .layout-2-inner > * {
        margin-top: 0;
        margin-bottom: 15px;
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;
    }

    @media (min-width: 768px) {
        .layout-2-inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 30px;
        }
    }

    @media (min-width: 1600px) {
        .layout-2 {
            max-width: 1800px;
        }
        .layout-2-inner {
            padding: 0;
        }
    }

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    }

    .pt-classic-list {
        padding: 0 10px;
        font-weight: 400;
        text-align: left;
    }

    .pt-classic-list li {
        position: relative;
        padding-left: 30px;
        display: block;
        color: $primary-text-color;
        line-height: 1.3;
    }

    .pt-classic-list li::before {
        content: "\ec59";
        position: absolute;
        left: 0;
        top: -1px;
        font-family: Linearicons;
        color: #1e266d;
        font-size: 1.2em;
        line-height: inherit;
    }

    .pt-classic-list li + li {
        margin-top: 14px;
    }

    * + .pt-classic-list {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    @media (min-width: 576px) {
        .pt-classic-list {
            padding: 0 15px;
        }
    }

    @media (min-width: 768px) {
        .pt-classic-list li + li {
            margin-top: 17px;
        }
        * + .pt-classic-list {
            margin-top: 1.75em;
            margin-bottom: 1.75em;
        }
    }

    @media (min-width: 1200px) {
        * + .pt-classic-list {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }

    .section-layout-3::after {
        content: "";
    }

    .page {
        position: relative;
        overflow: hidden;
        min-height: 100vh;
        opacity: 0;
    }

    .page-header-3 .rd-navbar-classic.rd-navbar-static .rd-navbar-link-1:not(:hover) {
        background-color: transparent;
    }

    * + p {
        margin-top: 15px;
    }

    p {
        a {
            color: $primary-color;
        }
    }

    .section-1 {
        padding: 15px 0 30px;
    }

    @media (min-width: 768px) {
        .section-1 {
            padding: 60px 0 70px;
        }
    }

    @media (min-width: 1200px) {
        .section-1 {
            padding: 80px 0;
        }
    }

    @supports (display: grid) {
    }

    @media (min-width: 1200px) {
        @supports (object-fit: cover) {
        }
    }

    .section-layout-3-outer {
        display: flex;
        flex-direction: column;
    }

    .section-layout-3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    .section-layout-3 > * {
        width: 100%;
    }

    .section-layout-3::after {
        align-content: space-between;
        opacity: 0;
        visibility: hidden;
    }

    .animated {
        animation-duration: 1s;
        animation-fill-mode: both;
        opacity: 1;
    }

    .preloader.loaded {
        visibility: hidden;
        opacity: 0;
    }

    @supports (clip-path: polygon(0 0)) {
    }

    .preloader {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background: #fff;
        transition: 0.1s;
    }

    #loading {
        background-color: #fff;
    }

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    }

    .pt-classic-list li {
        font-weight: 300;
    }

    h3 {
        font-size: 25px;
    }

    .section-1 {
        margin-top: -60px;
    }

    .logo {
        width: 160px;
        display: block;
        margin: 0 auto 20px;
    }

    .horizontal-list-of-logos {
        justify-content: center;
        margin: 25px 0 0;
    }

    .horizontal-list-of-logos li img {
        height: 25px;
    }

    .pt-classic-list {
        padding: 0;
    }

    .quote-with-image {
        &-wrapper {
            margin-top: 25px;
            border-top: 1px solid $default-grey;
        }
        margin: 20px 0 20px;
    }

    @media (max-width: 991.98px) {
        .logo,
        .section-layout-3 {
            display: block;
        }
        .layout-2 {
            padding: 8px;
            text-align: center;
        }
        h3 {
            font-size: 20px;
        }
        .box-shadow-1 {
            padding: 15px 20px;
        }
        .quote-with-image {
            margin: 25px 0 20px;
        }
        .quote-with-image .bp-max-768-flex {
            align-items: center;
        }
        .quote-with-image p {
            margin-top: -8px;
        }
        .quote-with-image .image-circle {
            width: 65px;
            height: 65px;
            min-width: 65px;
            margin-right: 24px;
        }
        .container {
            padding-right: 12px;
            padding-left: 12px;
        }
        .horizontal-list-of-logos {
            margin: 15px 0 0;
        }
        .horizontal-list-of-logos li img {
            height: 21px;
        }
        .border-button {
            padding: 5px 15px 5px 12px;
        }
        .border-button.google-g {
            padding: 5px 15px 5px 35px;
        }
        .border-button.google-g:before {
            width: 17px;
            height: 17px;
            left: 8px;
            top: 8px;
        }
        .section-layout-3-main {
            margin-top: 50px;
        }
        .logo {
            width: 120px;
            margin: 0 auto 12px;
        }
        .pt-classic-list li + li {
            margin-top: 10px;
        }
    }

    .email-login-and-registration {
        margin: 2em 0;
    }

    &__legal-checkbox {
        font-size: 0.85em !important;
        font-weight: 300;
        color: $secondary-text-color;

        a {
            color: $primary-color;
        }
    }
}

.email-login-and-registration {
    &__switch-mode {
        padding: 0 !important;
        margin-bottom: 1em !important;
    }
}
