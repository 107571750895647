@import 'ui/Common.scss';

.template-action-provider {
  .settings-title__right {
    transition: ease opacity 1s;
    opacity: 0;
    pointer-events: none;
    .delete-confirmation-text {
      color: red;
    }
  }

  .ellipsis-title {
    @extend .ellipsis-title;
  }

  &:hover {
    .settings-title__right {
      transition: ease opacity 1.5s;
      opacity: 1;
      cursor: pointer;
      .delete-confirmation-text {
        color: red;
      }
    }
  }

  .template-block__inner {
    cursor: initial;
    border: 1px solid $default-grey;
    background-color: white;

    .ant-typography {
      font-size: 0.97em;
      font-weight: 400;
    }
    &:hover {
      transform: initial;
      box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 5%);
    }
    .overlay {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      z-index: 400;
      cursor: auto;
      backdrop-filter: blur(2px);
      background-color: rgba(0, 0, 0, 0.015) !important;
      display: none;

      span {
        padding: 10px;
        border-radius: 50%;
        background-color: $light-green;
        border: 1px solid $border-green;
        backdrop-filter: blur(2px);
        cursor: pointer;

        svg {
          font-size: 40px;
          fill: $green;
        }
      }
    }
  }

  input {
    border: 1px solid $light-border-grey !important;
    padding: 0 5px;
  }

  .image-upload-v3 {
    margin-bottom: 15px;
  }
}

.text-ai-modal-header {
  .ant-modal-header {
    padding: 16px 24px 0px 24px;
    background-color: #424242 !important;
    border-radius: 8px 8px 0 0 !important;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .ant-modal-close-icon {
    color: rgb(143, 143, 143);
    :hover {
      color: white;
    }
  }
}
