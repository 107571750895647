.customer-management-logs-wrapper {
  &__default-loader {
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
  }
  .ant-timeline-item-content {
    top: 0px !important;
    .ant-collapse-header {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      p{
        margin: 0px;
        font-weight: 400;
        strong {
          font-weight: 600;
        }
      }
    }
    .ant-table-wrapper {
      padding-bottom: 10px;
    }
  }
}

.customer-management-logs-modal {
  .ant-table-wrapper {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  &__form-wrapper{
    display: flex;
    flex-direction: column;
    .ant-select{
      width: 250px !important;
    }
  }
  &__submit-btn {
    width: fit-content;
  }
}

.modal-trigger-btn {
  width: fit-content;
}

.charge-form-modal-trigger{
  width: 100%;
  display: flex;
  justify-content: center;
}