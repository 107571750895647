.colorized-banner-element {
  height: 100px;
  padding: 24px 30px;
  display: flex;
  align-items: center;
  
  &__inner {
    width: 100%;

    .public-DraftStyleDefault-block {
      margin-bottom: 0;
    }
  }
}
