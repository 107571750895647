.funnel-folder {
  margin-inline: 12px;
  width: 100%;

  &__dragged-over {
    .ant-card {
      border: 0.25em solid $primary-color;
    }
  }

  .ant-card-body {
    padding: 0px !important;
  }

  .anticon-folder {
    font-size: 2.5em;
    color: $primary-color;
  }

  &__cover {
    height: inherit;
    display: flex;
    align-items: center;

    img {
      height: 60px !important;
      width: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 5px;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    }

    .ant-col {
      min-height: auto !important;
      padding: 4px;
    }

    &__folder-icon {
      font-size: 2rem;
      color: $primary-color;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .funnel-overview__card {
    height: inherit !important;
  }

  .ant-card-cover {
    height: 200px !important;
  }

  &:hover .ant-card-cover {
    height: 200px !important;
  }
}

.folder-internal {
  position: relative;

  .funnels-list {
    & > div {
      top: 82px !important;
    }
  }

  .ant-breadcrumb {
    position: absolute;
    z-index: 1;
    padding-block: 20px;
    padding-left: 40px;
    width: 100%;
    background-color: $light-grey;
  }

  .ant-breadcrumb-separator {
    font-weight: 800;
  }

  .ant-breadcrumb-link {
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
  }

  &__can-drop {
    span:first-child {
      .ant-breadcrumb-link {
        background-color: $light-green;
      }
    }
  }

  &__drop-active {
    span:first-child {
      .ant-breadcrumb-link {
        background-color: $secondary-color;
      }
    }
  }
}
