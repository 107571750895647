.upvoty-change-logs-drawer-button {
  border: 0px !important;
  margin: 0 !important;
  box-shadow: none !important;

  svg {
    color: #1E266D;
  }

  .anticon-bell {
    font-size: 22px !important;
  }
}

.upvoty-change-logs-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-header {
      padding: 20px 10px !important;
      background: #2E006E;

      .upvoty-change-logs-drawer-header {
        display: flex;
        justify-content: space-between;

        h4 {
          margin: 0px;
          color: white;
          margin-left: 15px;
          font-weight: 600;
        }
      }

      .ant-btn {
        background: #2E006E;
        box-shadow: none !important;
        border: 0px !important;
        margin-right: 10px;

        &:hover {
          color: white;
        }

        .anticon-close {
          color: white;
          font-size: 18px;
        }
      }
    }
  }

  .iframe-wrapper {
    position: relative;
    height: 100%;

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;

      .anticon-loading {
        font-size: 30px !important;
        z-index: 99;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  //Firefox
  scrollbar-width: none;

  //Internet Explorer, Edge
  -ms-overflow-style: none;

  .ant-drawer-close {
    display: none;
  }
}
