.secondBoarding {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  .icon-provider {
    > svg {
      color: $primary-color;
    }

    > span {
      opacity: 0.7;
    }
  }

  .ant-progress-bg {
    height: 15px !important;
  }

  .pageCount {
    margin-top: 1em;
    opacity: 0.7;
  }

  .question-wrapper__question {
    color: $primary-text-color !important;
    font-size: 1.6em;
    font-weight: 600;
  }

  .choice-card {
    cursor: pointer;

    &:hover,
    & {
      transition: ease transform 0.5s;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  @include breakpoint(min_sm) {
    font-size: 1.3em;

    .choice-card__inner {
      min-height: 220px;
    }
  }
}

ol.progtrckr {
  display: none;
}
