.funnels-list {
  width: 100%;
  height: 100%;
  &[data-route*="papierkorb"]{
    & > div {
      position: initial !important;
    }
  }
  & > div {
    top: 40px !important;
  }
  .virtuoso-grid-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.50%;
    padding: 40px;
    padding-bottom: 60px !important;
    @include breakpoint(max_md) {
      padding: 10px;
    }
    .virtuoso-grid-item {
      display: flex;
      flex: none;
      align-content: stretch;
      box-sizing: border-box;
      max-height: 600px;
      width: 24.5%;
      margin-block: 0.5%;

      @include breakpoint(max_1440) {
        width: 33%;
        margin-block: 0.33%;
      }
      @include breakpoint(md) {
        width: 49.5%;
        margin-block: 0.5%;
      }
      @include breakpoint(max_sm) {
        width: 99%;
        margin-block: 1%;
      }
      .ant-skeleton-button {
        height: 300px;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
.funnels-list-empty-view {
  align-items: center;
  text-align: center;
  height: 80vh;

  .create-funnel-text-header {
    max-width: 330px;
  }
  .create-funnel-inner-text-header {
    max-width: 420px;
  }
  .empty-view-create-funnel-button {
    border-radius: 5px;
  }
  .empty-view-create-funnel-button:hover {
    transform: scale(1.03);
    transition: transform 0.3s ease-in-out;
    opacity: 1 !important;
  }

  @include breakpoint(max_415) {
    img {
      width: 300px;
      height: 240px !important;
    }
    .create-funnel-inner-text-header {
      width: 300px;
      text-align: center;
    }
    .create-funnel-text-header {
      width: 280px;
      text-align: center;
    }
  }
}

.ant-layout.ui__main-content.expanded{
  .virtuoso-grid-item {
    @include breakpoint(min_md) {
      width: 49.5% ;
      margin-block: 0.33% ;
    }
    @include breakpoint(max_md){
      width: 99% ;
      margin-block: 1% ;
    }
    @media (min-width: 1366px) {
      width: 33%;
      margin-block: 0.33%;
    }

    @media (min-width: 1920px) {
      width: 24.5%;
      margin-block: 0.5%;
    }
  }
}
