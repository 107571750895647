/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/dashboard/fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/dashboard/fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/dashboard/fonts/open-sans-v34-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/dashboard/fonts/open-sans-v34-latin-300.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/dashboard/fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/dashboard/fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/dashboard/fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/dashboard/fonts/open-sans-v34-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/dashboard/fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/dashboard/fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/dashboard/fonts/open-sans-v34-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('/dashboard/fonts/open-sans-v34-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/dashboard/fonts/open-sans-v34-latin-600.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
$collapsedMargin: 67px;

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  color: $primary-text-color !important;
  background: #fff !important;
}

p {
  color: $secondary-text-color;
  font-weight: 300;
}

b {
  color: $primary-text-color;
}

a {
  &.link {
    color: $primary-color;
    text-decoration: none !important;

    &__disabled {
      opacity: 0.5;
      color: $secondary-text-color;
      cursor: not-allowed;

      &:hover {
        opacity: 0.5 !important;
        color: $secondary-text-color;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  svg {
    margin-right: 0.5em;
  }
}

ol {
  color: $primary-text-color !important;
  margin-left: 1em;
}

#root,
.app,
.ant-layout {
  height: 100%;
  background: #fff !important;
}

.ant-layout-has-sider {
  overflow-y: hidden;
}

.ant-layout-sider-light {
  z-index: 9;
  top: 0px;
}

.ant-layout-header {
  @media (min-width: 991px) {
    z-index: 9;
  }
}

.ant-modal {
  margin-top: 30px !important;
}

.steps-full-width-content {
  &.ant-steps-dot .ant-steps-item-content,
  &.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: initial !important;
  }
}

.ant-modal-content,
.ant-modal-header {
  border-radius: $default-border-radius !important;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 10;
}

.sider__bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  // padding: 15px;
  padding: 15px 15px 0px 0px;
}

.support-info {
  cursor: pointer;

  &:hover {
    transition: ease all 0.5s;

    .anticon {
      font-size: 1.15em;
    }
  }

  .anticon {
    transition: ease all 0.5s;
    margin-right: 0.35em;
    color: $secondary-color;
  }
}

.ant-layout-sider-zero-width {
  .sider__bottom {
    display: none;
  }
}

.uppercase-text {
  text-transform: uppercase;
}

.border-grey-text {
  color: $form-border-grey;
}

.text-crossed {
  text-decoration: line-through;
}

.small-text {
  font-size: 0.75em;
  line-height: 1.25em;
}

.medium-text {
  font-size: 0.85em !important;
  line-height: 1.25em !important;
}

.text-center {
  text-align: center !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-secondary {
  color: $secondary-text-color !important;
}

.text-small {
  font-size: $font-size-small;
}

.text-mini {
  font-size: $font-size-mini;
}

.text-bigger {
  font-size: $font-size-bigger;
}

.text-bold {
  font-weight: 600;
}

.text-red {
  color: $red !important;

  & svg {
    color: $red !important;
  }
}

.brand-color-text {
  &__primary {
    color: $primary-color !important;
  }
  &__secondary {
    color: $secondary-color !important;
  }
}

.ant-layout-content {
  position: relative;
}

.ant-layout-header {
  padding: 0 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-border-grey;

  h1 {
    margin: 0;
  }
}

.anticon-loading.centered {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $secondary-color;
}

.rows-with-top-down-padding {
  > .ant-row {
    padding: 8px 0;
  }
}

[current-route='kontakte'] {
  .max-width__1980px-centered {
    max-width: initial;
  }
}

@include breakpoint(max_md) {
  .ant-layout-sider-zero-width-trigger {
    top: 11px !important;
    right: -40px !important;
  }

  .ant-layout {
    // min-width: 100%;

    .ant-layout-content {
      margin: 0;
    }

    .ant-layout-header {
      .ant-avatar-lg {
        width: 35px;
        height: 35px;
      }
    }
  }

  [current-route='onboarding-einfuehrung'],
  [current-route='onboarding-fragen'] {
    .ui__logo {
      margin: 6px;
    }
  }
}

[current-route='onboarding-einfuehrung'],
[current-route='analyse'],
[current-route='checkout'],
[current-route='onboarding-fragen'] {
  .ant-layout {
    .ant-layout-header {
      width: 100vw !important;
      border-bottom: 1px solid $light-border-grey !important;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.0675) !important;
    }
  }
}
[current-route='kontakte'][current-role='AGENCY_CUSTOMER'] {
  .ant-layout {
    flex-direction: row;
    .ant-layout-header {
      width: 100vw !important;
    }
  }
}

[current-route='bearbeiten'] {
  .ui {
    &__sub-header-background {
      .support-info {
        margin-right: 160px;
        border-right: 1px solid $default-grey;
      }
    }
  }
}

[current-role='AGENCY_CUSTOMER'] {
  .ui {
    &__sub-header-background {
      @include breakpoint(max_sm) {
        padding: 0 10px !important;
      }
    }
  }
}

.ui {
  &__sub-header {
    &__middle {
      .funnel-search {
        display: none;
      }
    }
  }
}

[current-route*='meine-funnels'] {
  .ui {
    &__sub-header {
      &__middle {
        .funnel-search {
          display: initial;

          svg {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.ui {
  &__header {
    border-bottom: 1px solid $light-border-grey;
  }

  &__logo {
    &.without-name {
      width: unset;
    }

    img {
      height: 40px;
    }
  }

  &__top-layer {
    .ant-layout-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    @include breakpoint(max_md) {
      .ant-layout-header {
        .anticon-home,
        .anticon-close {
          font-size: 1.5em;
        }
      }
    }
  }

  &__top-layer .ui__content {
    margin-top: 60px;
    padding: 30px 35px;
    
    @include breakpoint(max_md) {
      padding: 25px;
    }
  }

  &__sub-header {
    &__left,
    &__middle,
    &__right {
      display: flex;
      align-items: center;
    }

    &__left {
      h1 {
        font-weight: 600;
        font-size: 1.25em;
      }
    }

    &__right__icons-right {
      border-left: 1px solid $light-border-grey;
      display: flex;
      column-gap: 1em;
      padding-left: 20px;
      align-items: center;
      height: 64px;
    }

    &__right__funnel-icons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1em;
      margin-right: 20px;

      .funnel-icon {
        display: flex;
        align-items: center;
      }

      .funnel-view-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #e8e8e8;
        border-radius: 6px;

        div {
          padding: 4px 8px;
          display: flex;
          align-items: center;
          background-color: white;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }

        svg {
          width: 18px;
        }

        .agency-dashboard-brand-background-color {
          svg {
            color: white;
          }
        }

        .not-selected-icon {
          cursor: pointer;
        }
      }
    }

    &__middle {
      transform: translateX(-25px);
    }

    &-background {
      background: #fff !important;

      .support-info {
        height: 100%;
        line-height: 1.75em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        padding-right: 30px;
      }

      .ant-btn {
        margin-right: 16px;
        vertical-align: middle;
      }

      .ant-avatar,
      .sb-avatar {
        vertical-align: middle;
        cursor: pointer;

        &,
        &:hover {
          transition: transform ease 0.5s;
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      .anticon-arrow-left,
      .anticon-home,
      .anticon-close,
      .anticon-left {
        font-size: 1.5em;
        color: $primary-color;
        opacity: 0.2;

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    min-height: unset !important;
    padding: 30px;

    @include breakpoint(max_md) {
      padding: 25px;
    }
  }

  &__footer {
    text-align: center;
  }

  &__white-round-wrapper {
    background: #fff;
    margin: 15px 0 25px;
    padding: 20px 25px;
    border-radius: $default-border-radius;
    box-shadow: $card-shadow;
    border: 1px solid $light-border-grey;

    &:first-child {
      margin-top: 0 !important;
    }

    &.with-handle {
      position: relative;
      padding: 20px 25px 30px 40px;
    }

    &__action-bar {
      padding-bottom: 6px;
      text-align: right;

      .anticon-delete {
        color: $red !important;
      }
    }

    &__handle {
      cursor: move;
      position: absolute;
      left: 0;
      top: 0;
      background: $light-grey;
      color: $default-grey;
      height: 100%;
      font-size: 20px;
      display: flex;
      align-items: center;
      border-radius: 8px 0 0 8px;
      border-right: 1px solid $light-border-grey;
    }

    .ant-tabs-bar {
      margin-bottom: 0 !important;
    }

    .ant-tabs-tab {
      padding: 3px 0 !important;
      font-weight: 300 !important;
      margin-right: 50px !important;

      &:last-child {
        margin-right: 0 !important;
      }

      &-active {
        font-weight: 400 !important;
      }
    }

    &.disabled {
      position: relative;
      opacity: 0.25;

      &:after {
        border-radius: $default-border-radius;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        content: ' ';
        background: rgba(0, 0, 0, 0.25);
      }
    }

    &.ui__white-round-wrapper__250-scrollable {
      overflow-y: scroll;
      height: 250px;
      box-shadow: inset 0px -10px 5px -5px rgba(0, 0, 0, 0.1);
    }
  }

  &__icon-circle {
    width: 35px;
    min-width: 35px;
    height: 35px;
    background: #1890ff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;

    &.small {
      width: 23px !important;
      min-width: 23px !important;
      height: 23px !important;
    }
  }

  &__display-none {
    &,
    &.ant-form-item {
      display: none !important;
    }
  }

  &__display-block {
    &,
    &.ant-form-item {
      display: block !important;
    }
  }

  &__label-switch {
    display: flex !important;
    align-items: center;

    .ant-switch {
      margin-right: 10px;
    }
  }

  &__both-side-icon-switch {
    .ant-switch-small {
      margin: 0 10px;
    }

    .anticon {
      color: #888;
    }
  }
}

.ant-card {
  border-radius: $default-border-radius !important;
  box-shadow: $card-shadow;

  .ant-card-actions {
    border-radius: 0 0 $default-border-radius $default-border-radius;
  }

  .ant-card-cover {
    &,
    & > * {
      border-radius: $default-border-radius $default-border-radius 0 0;
    }
  }

  &.card__center-icon {
    &,
    & .ant-card-body {
      height: 100%;
    }

    & .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;

      .anticon {
        font-size: 3em;
        color: $primary-color;
      }
    }
  }
}

.form-group-inline {
  display: flex;

  > * {
    flex: 1;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  &.remove-margin-bottom {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-dropdown-trigger {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    > * {
      flex: 1;
      text-align: left;
    }

    .anticon-down {
      text-align: right;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  &.remove-form-item-margin {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  @include breakpoint(max_575) {
    display: block;

    > *:first-child {
      margin-right: 0;
    }

    > *:last-child {
      margin-left: 0;
    }
  }
}

.remove-margin {
  margin: 0 !important;

  &.ant-alert {
    margin: 0 !important;
  }

  &-top {
    &,
    &.ant-alert {
      margin-top: 0 !important;
    }
  }

  &-left {
    &,
    &.ant-alert {
      margin-left: 0 !important;
    }
  }

  &-right {
    &,
    &.ant-alert {
      margin-right: 0 !important;
    }
  }

  &-bottom {
    &,
    &.ant-alert,
    & .ant-form-item-label label {
      margin-bottom: 0 !important;
    }
  }
}

.remove-padding {
  &-top {
    &,
    &.ant-alert {
      padding-top: 0 !important;
    }
  }

  &-left {
    &,
    &.ant-alert {
      padding-left: 0 !important;
    }
  }

  &-right {
    &,
    &.ant-alert {
      padding-right: 0 !important;
    }
  }

  &-bottom {
    &,
    &.ant-alert {
      padding-bottom: 0 !important;
    }
  }
}

.flex-group {
  display: flex;

  .ant-btn-link {
    margin-right: 1em;
  }
}

.form-switch-block {
  border: 1px solid $form-border-grey;
  padding: 0 10px !important;
  height: 34px;
  align-items: stretch;
  margin: 1em 0 !important;

  &.ant-form-item {
    display: flex !important;
  }

  .ant-form-item-label {
    padding: 0 !important;

    &,
    label {
      height: 100% !important;
      margin-top: 0 !important;
      display: inline-flex !important;
    }

    label {
      &:after {
        display: none;
      }
    }
  }

  button {
    float: right;
  }
}

.ant-radio-group {
  .anticon {
    margin-right: 0.5em !important;
  }

  &.fluid {
    display: flex;

    label {
      flex: 1 !important;
      text-align: center !important;
    }
  }
}

.rdw-editor-main {
  h2 {
    font-size: 1.195em;
  }

  h3 {
    font-size: 1em;
  }

  img {
    max-width: 100%;
  }

  .public-DraftStyleDefault-block {
    color: $secondary-text-color !important;
  }
}

.rdw-link-modal {
  height: initial !important;
  min-height: 205px;
}

.flex-wrapper-two {
  display: flex !important;
  justify-content: left !important;

  &.vertical-centered {
    align-items: center;
  }

  &__item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.flex-vertical-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  & > *:last-child {
    margin-bottom: 0;
  }

  .ant-form-item {
    width: 100%;
  }
}

.flex-align-items-right {
  align-items: flex-end;
}

.flex-vertical-center-inline {
  display: flex;
  align-items: center;
}

.divider-remove-left-space {
  margin: 50px 0 16px !important;

  &:before {
    width: initial !important;
  }

  .ant-divider-inner-text {
    padding-left: 0 !important;
    font-weight: 400;
  }
}

.ant-form-item-label {
  label {
    font-weight: 400 !important;
    width: 100%;
  }

  &__text {
    float: right;
    margin-left: auto;
  }
}

.ant-form {
  > .divider-remove-left-space {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}

.flex-label {
  display: flex !important;
  flex-direction: row;

  .ant-form-item-control-input {
    min-height: initial !important;
  }

  .ant-form-item-label {
    margin-right: 15px !important;
  }

  > * {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.disable-layer {
  &__disabled {
    user-select: none;
    pointer-events: none;

    * {
      pointer-events: none;
      user-select: none;
      opacity: 0.6;
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }
}

.ant-tabs-extra-content .ant-tabs-new-tab {
  width: 26px !important;
  height: 26px !important;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  border-color: #1890ff !important;
  background-color: #1890ff !important;

  &:hover {
    transform: scale(1.05);
  }
}

.ant-empty-image svg {
  font-size: 100px;
  color: $default-grey;
}

.profile-popover {
  &__header {
    padding: 5px 0;

    p {
      font-weight: 300;
    }

    & .ant-avatar-circle,
    & .sb-avatar {
      margin-right: 10px !important;
    }
  }

  &__item {
    cursor: pointer;
    padding: 0.5em 1em;

    &:hover {
      background: $light-grey;
    }

    .anticon {
      margin-right: 0.5em;
    }
  }

  &__logout {
    color: $red;
  }
}

button.icon-circle-button-with-label {
  margin-left: auto !important;
  margin-right: auto !important;
  height: initial !important;
  border: none !important;
  box-shadow: initial !important;
  display: flex;
  align-items: center;

  & .ui__icon-circle {
    margin-right: 15px;
  }
}

.button-green {
  &.ant-btn.ant-btn-primary {
    background-color: $green !important;
    border-color: $border-green !important;
    color: #fff;

    * {
      color: #fff !important;
    }

    &:hover {
      border-color: $green !important;
      opacity: 0.9;

      * {
        color: #fff !important;
      }
    }
  }

  &.ant-btn {
    border-color: $border-green;
    color: $border-green;

    &:focus {
      border-color: $border-green;
    }

    svg {
      color: $border-green;
    }
  }
}

.max-width {
  &__150px {
    &,
    &-centered {
      max-width: 150px;
    }

    &-centered {
      margin: 0 auto;
    }
  }

  &__250px {
    &,
    &-centered {
      max-width: 250px;
    }

    &-centered {
      margin: 0 auto;
    }
  }

  &__450px {
    &,
    &-centered {
      max-width: 450px;
    }

    &-centered {
      margin: 0 auto;
    }
  }

  &__600px {
    &,
    &-centered {
      max-width: 600px;
    }

    &-centered {
      margin: 0 auto;
    }
  }

  &__1200px {
    &,
    &-centered {
      max-width: 1200px;
    }

    &-centered {
      margin: 0 auto;
    }
  }

  &__1980px {
    &,
    &-centered {
      max-width: 1980px;
    }

    &-centered {
      margin-left: auto !important;
      margin-right: auto !important;
      width: 100%;
    }
  }
}

.ant-empty {
  &-footer {
    h2 {
      line-height: 1em !important;
    }

    .ant-btn {
      min-width: 100px !important;
      margin: 10px 5px !important;
    }
  }

  &-description {
    max-width: 800px;
    margin: 0 auto !important;

    h1 {
      font-size: 2.5em;
      font-weight: 600;
    }
  }
}

.ant-alert {
  margin: 24px 0 !important;
}

.sketch-picker {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px !important;
}

.rocketchat-widget {
  display: none !important;
}

.default-loader {
  position: absolute;
}

.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 110;
}

.site-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  @include breakpoint(max_md) {
    .ant-page-header-heading-extra {
      width: calc(100% - 32px);
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.default-popover {
  &.anticon {
    svg {
      color: $secondary-color !important;
    }
    margin-left: 0.5em;
  }

  &__content {
    padding: 0.5em 1em;
    max-width: 400px;
  }

  &__item {
    padding: 0.5em 0;

    .ant-select,
    .ant-picker {
      width: 100%;
    }

    label {
      display: block;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.switch-right {
  .ant-switch {
    float: right;
  }
}

.switch-left-right-labels {
  margin: 1em 0 2em;

  button.ant-switch {
    margin: 0 1em;
  }
}

.chaport-container.chaport-container-positioned {
  right: 15px !important;
}

ul.inline-list {
  display: flex;
  list-style: none;
  margin-top: 0.75em;

  li {
    margin-right: 1.25em;
  }

  @include breakpoint(max_575) {
    display: initial;

    li {
      margin-right: 0;
      margin-bottom: 0.45em;
    }
  }
}

ul.light-list {
  margin-left: 1.25em;
  font-weight: 300;
  margin: 0.5em 1.25em;
}

.overlay {
  &-wrapper {
    position: relative;
  }

  &-dimmer,
  &-empty {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  &-empty {
    z-index: 5 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    justify-content: center;
    margin: 0 auto !important;
  }

  &-dimmer {
    backdrop-filter: blur(3px);
    background: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
  }
}

.green-text {
  color: $green !important;

  & > * {
    color: $green !important;
  }
}

.red-text {
  color: $red !important;

  & > * {
    color: $red !important;
  }
}

.announcekit {
  &-widget {
    position: relative;
    height: 100%;
    margin: 0 1em;
    @include breakpoint(max_575) {
      display: none;
    }
  }

  &-bell {
    height: 1.75em;
    width: 1.75em;
    transform: translateY(7px);

    & > svg {
      height: 100%;
      width: 100%;
    }
  }

  &-badge-wrapper {
    position: absolute;
    z-index: 2;
    right: -1.7em;
    top: 1.2em;
    width: 35px;
    height: 35px;
  }
}

.hidden-on {
  &__max-sm {
    @include breakpoint(max_sm) {
      display: none !important;
    }
  }
}

.display-initial {
  display: initial !important;
}

.pointer {
  cursor: pointer;
}

.react-16-9-player {
  background: $light-grey;
}

.padding-left {
  &-10 {
    padding-left: 10px !important;
  }

  &-15 {
    padding-left: 15px !important;
  }

  &-25 {
    padding-left: 25px !important;
  }
}

.default-popover {
  &__title,
  &__content {
    display: flex;
  }

  &__title {
    margin-bottom: 0px;

    .anticon {
      display: flex;
      align-items: center;
      margin-right: 0.5em;
      color: $secondary-color;
    }
  }

  &__content {
    padding: 1em;
    flex-direction: column;
    max-width: 250px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.ant-input-number {
  width: 100% !important;
}

.meetovo-funnel-builder-layout svg {
  vertical-align: initial;
}

.d-grid {
  display: grid;

  &__col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__col-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.ant-btn {
  &-circle {
    border-radius: 50% !important;
  }

  &-round {
    border-radius: 32px !important;
  }

  & > {
    svg {
      vertical-align: middle;
      margin-top: -2px;

      & + span {
        margin-left: 8px;
      }
    }
  }

  &.ant-btn__without-background {
    border: 0px;
    background-color: transparent;
    box-shadow: initial;
  }

  &.ant-btn__disabled-default-style[disabled] {
    color: inherit !important;
    background-color: $light-grey-medium !important;
  }
}

.icon-btn {
  border: 0px;
  background-color: transparent;
  margin: 5px;
  color: $primary-color;
  font-size: 20px;
  font-weight: 900;
  vertical-align: middle;
}

.small-label {
  font-weight: 300 !important;
  color: $secondary-text-color !important;
  font-size: 0.97em !important;
}

.default-opacity-hover {
  cursor: pointer;
  @include defaultOpacityHover(0.7);
}

.decent-border {
  border: 1px solid #eee;
  border-radius: 8px;
}

[contenteditable='true'] {
  cursor: text;
}

.meetovo-border {
  background: $primary-gradient;
  padding: 6px;
  border-radius: 10px;

  &__inner {
    background-color: #fff;
    padding: 6px;
    padding-bottom: 0;
    border-radius: 8px;
  }
}
.ui__ {
  &main-content {
    overflow-x: inherit !important;
    @include breakpoint(max_993) {
      margin-left: 0px;
    }
  }

  &content {
    margin-top: $header-height !important;
    padding-bottom: 100px;
  }

  &header {
    display: flex;
    flex-grow: 1;
    position: fixed;
    height: $header-height;
    padding: 0;
    top: 0;
    right: 0;
    z-index: 1;

    &.collapsed {
      width: calc(100vw - $collapsedMargin);
    }

    &.expanded {
      width: calc(100vw - $sidebar-width);
    }
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

[current-route*='share'] {
  .ui__ {
    &header {
      width: 100vw;
    }
  }
}

[current-route='kontakte'] {
  .ant-layout-has-sider {
    overflow-y: unset !important;
  }
  .ui__ {
    &main-content {
      margin-left: $sidebar-width;
      &.collapsed {
        margin-left: $collapsedMargin;
      }
      &.expanded {
        margin-left: $sidebar-width;
      }
    }
    &header {
      &.collapsed {
        width: calc(100vw - $collapsedMargin);
      }

      &.expanded {
        width: calc(100vw - $sidebar-width);
      }
    }
  }

  @include breakpoint(max_md) {
    .ui__sub-header__left {
      h1 {
        display: none;
      }
    }
  }
}

.ant-btn {
  .anticon {
    vertical-align: middle;
  }

  &-link {
    border: none !important;
  }

  &:hover {
    color: $primary-color !important;
  }

  &-primary {
    background-color: $primary-color !important;

    &.ant-btn-dangerous {
      background-color: $red !important;
      border-color: $red !important;

      &:hover,
      &:focus,
      &:active {
        border-color: $red !important;
      }
    }

    &.green {
      background-color: $green !important;
      border-color: $green !important;

      &:hover,
      &:focus,
      &:active {
        border-color: $green !important;
      }
    }

    &[disabled],
    &:focus,
    &:active {
      .anticon {
        color: #fff !important;
      }
    }

    @include defaultOpacityHover(0.7);

    &[disabled] {
      opacity: 1;
    }

    &-disabled,
    &.disabled,
    &[disabled],
    &-disabled:hover,
    &.disabled:hover,
    &[disabled]:hover,
    &-disabled:focus,
    &.disabled:focus,
    &[disabled]:focus,
    &-disabled:active,
    &.disabled:active,
    &[disabled]:active,
    &-disabled.active,
    &.disabled.active,
    &[disabled].active {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;

      .anticon {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }

    &:hover {
      color: white !important;
    }
  }

  &-circle {
    padding: 0 !important;
  }

  &-primary,
  &:hover,
  &:focus,
  &:active {
    border-color: $primary-color !important;
  }

  &:disabled {
    border-color: #d9d9d9 !important;
  }
}

.ant-switch-checked {
  background-color: $secondary-color !important;
}

.block-on-funnel-invalidation {
  &.blocked {
    opacity: 0.5;
  }
}

.ant-result {
  &-title {
    line-height: 1.25 !important;
    margin-bottom: 0.5em !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.disbabled {
  filter: grayscale(1);
  opacity: 0.075 !important;
  pointer-events: hover;
  cursor: not-allowed;
}

.parent-hover-child-visible {
  &__child {
    display: none;
  }

  &:hover {
    .parent-hover-child-visible__child {
      display: initial;
    }
  }
}

.table-overflow-enabled .ant-table .ant-table-content {
  overflow: auto;
}

.ant-select-multiple .ant-select-selection-item-content {
  max-width: 160px;
}

.submenue-text {
  padding-left: 8px;
}

.sidebar-submenu {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.ant-steps-item-content {
  width: initial;
  text-align: left;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #fff !important;
}

.submenue-icon {
  margin-right: 0.5em;
  line-height: 0;
}

.sidebar-icons__submenu-item.ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
  height: 35px;
  line-height: 35px;
}

.ant-tag > svg {
  transform: translateY(2px);
  margin-right: 5px;
}

.ant-select {
  &-selection-item,
  &-item-option-content {
    .custom-select-label {
      img {
        width: 20px;
        margin-right: 8px;
        margin-top: -2px;
      }
    }
  }

  &-item-option-content {
    .custom-select-label {
      img {
        width: 22px;
        margin-right: 8px;
      }
    }
  }
}

.react-tel-input .form-control {
  color: rgba(0, 0, 0, 0.65);
}

.challenge-breadcrumb {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &__challenge {
    color: '#686868';
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
  }

  &__module {
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 15px;
    background: #f2f2f4;
    height: 28px;
    display: flex;
    align-items: center;
    font-weight: 600;
    white-space: nowrap !important;
  }
}

.flex-spacer {
  flex-grow: 1;
}

.draggable {
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.marked-text {
  max-height: 140px;
  overflow: hidden;
  position: relative;

  & > * {
    background: $light-grey-medium;
    margin-bottom: 0.5em !important;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    font-size: 1.15em;
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    content: ' ';
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
}

.image-v3-height {
  height: 150px !important;
}

.branding-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__color-picker {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  @media (max-width: 1200px) {
    &__color-picker {
      padding-top: 10px;
      flex-direction: row;
      align-items: center;
    }
  }
}
