.img-element {
  &__wrapper {
    padding: 0;

    // Disabling grammarly Extention in Image for Taking Screen Shots
    [contenteditable] ~ grammarly-extension,
    input ~ grammarly-extension,
    textarea ~ grammarly-extension {
      display: none;
    }
    .content-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      &__inner {
        min-width: 100%;
      }
    }
    .spinner__wrapper {
      border-radius: 0;
    }

    .rich-text {
      &__inner {
        text-shadow: 4px 4px 8px rgb(0 0 0 / 60%);
      }
    }
  }

  &__overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    background-color: #000;
  }
}
