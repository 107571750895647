.onboarding__button {
  margin-top: 2em;
  background-color: #1a1b1f;
  background-image: linear-gradient(338deg, #1e266d, #23d3d3) !important;
  font-size: 1.2em;
  font-weight: bold;
  border: none !important;

  @include breakpoint(max_md) {
    margin-top: 1.5em;
  }
}

.react-player__play-icon {
  border-width: 30px 0 30px 50px !important;
}

.bg__white {
  background-color: $color-high-contrast;
}

.bg_lightgray {
  background-color: $light-grey;
}

.firstboarding {
  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(max_md) {
      align-items: flex-start !important;
    }
  }
  &__antrow {
    flex-direction: row;

    @include breakpoint(max_md) {
      flex-direction: column-reverse !important;
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2em;
    height: 100%;
    justify-content: center;

    @include breakpoint(max_md) {
      padding: 0;
      margin-top: 1em !important;
      text-align: center;
      align-items: center;
    }
  }
}

.stack {
  &__outer {
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;

    @include breakpoint(max_md) {
      padding: 0;
      margin-top: 8em !important;
      padding-bottom: 10em !important;
    }
  }
  &__top {
    position: inherit;
    z-index: 2;
    overflow: hidden;
    border-radius: 15px;

    .anticon-play-circle {
      font-size: 9em;
      color: $secondary-color;
      opacity: 0.6;
      animation: scalePulse 4s infinite;
    }
  }
  &__bottom {
    img {
      position: absolute;
      z-index: 1;
      top: -20%;
      left: -20%;
      width: 80%;

      @media (max-width: 991px) {
        width: 95%;
      }
    }
  }
}

[current-route*="onboarding-"] {
  .ant-layout-header {
    z-index: 120;
  }
}

@keyframes scalePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
