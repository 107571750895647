.ant-tabs {
  overflow-x: hidden !important;

  &-tab {
    &:hover,
    &-active {
      color: $primary-color;
    }
  }

  &-ink-bar-animated {
    background: $primary-color;
  }
}

.ant-slider {
  &-handle {
    border: solid 2px $primary-color;
  }
  &-track {
    background: $primary-color;
  }
}
.ant-switch {
  border-radius: 20px;
}

.ai-text-settings {
  position: relative;

  .settings-missing-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h6 {
      text-align: center;
      font-size: 1em;
      padding-inline: 1em;
    }
  }

  .button-round-borders {
    border-radius: $default-border-radius;
  }

  .generated-text-wrapper {
    h1, h2, h3, h4 {
      font-size: 1em;
    }
  }

  @keyframes beat-effect {
    0%,
    100% {
      box-shadow: 0 0 0 rgba(3, 222, 29, 0);
    }
    50% {
      box-shadow: 0 0 35px rgba(3, 222, 29, 0.5);
    }
  }

  .focus-transition {
    animation: beat-effect 3s ease-in-out;
  }

  .text-limits {
    p {
      margin: 0px !important;
    }
  }

  .link-styles {
    text-decoration: underline;
    color: '#40A9FF';
    cursor:pointer;
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .ant-progress-text {
    margin-left: 0 !important;
    width: 100%;
  }

  .ant-progress-status-success .ant-progress-bg {
    background-color: $red !important;
  }

  .ant-progress-status-success .ant-progress-text {
    color: $red !important;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    padding: 2em;
  }
}

.builder {
  &__header {
    background-color: white;
    justify-content: space-between;
    box-shadow: initial;
    border-bottom: 1px solid $default-grey;
    line-height: initial;

    &__logo-wrapper {
      display: flex;
      align-items: center;

      &__title {
        font-size: 1.25em;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding: 0 1em;
      }

      &__logo {
        display: flex;
        align-items: center;

        * {
          padding: 0;
        }
      }

      &__container {
        font-size: 1.25em;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 1em;

        .funnel__title__msg {
          visibility: hidden;
          font-size: 14px;
          color: #ff4d4f;
        }

        .funnel__title {
          min-width: 50px;
          padding: 3px;
          font-weight: 500;
          border-radius: 3px;
          margin-bottom: 0;
        }

        &.title-invalid {
          .funnel__title {
            border: 1px solid #ff4d4f;
          }
          .funnel__title__msg {
            visibility: visible;
          }
        }

        .edit-icon {
          position: absolute;
          right: -38px;
          height: 100%;
          display: none;
          align-items: center;
          background: #fff;
          width: 30px;

          * {
            font-size: 12px;
          }
        }
      }

      &__container:hover {
        .edit-icon {
          display: flex;
        }
      }
    }

    &__right-wrapper {
      display: flex;
      align-items: center;

      button {
        margin-right: 1rem;
      }

      .anticon-close {
        font-size: 1.5em;
        color: $primary-color;
        opacity: 0.2;

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .view-changer {
      display: flex;

      .preview-size-icon {
        font-size: 20px;

        &.active {
          color: $primary-color;
          font-size: 22px;
          transition: font-size 0.1s;
        }
      }
    }
  }

  &__content {
    margin-top: 64px;
    width: 100%;
    display: flex;

    justify-content: center;

    .preview-section {
      overflow: auto;
      width: 100%;
      background-color: white;

      &__inner,
      &.mobile-view .preview-section__inner {
        transition: max-width 0.2s ease-in;
      }

      &.mobile-view {
        background: $light-grey;
        padding: 2.5rem 1.5rem;

        .preview-section__inner {
          border: 1px solid $default-grey;
          max-width: 500px;
          overflow: hidden;
          border-radius: $default-border-radius;
          margin: 0 auto;
          box-shadow: 1px 5px 15px 0px rgb(0 0 0 / 15%);

          & > div {
            & > .actions-controller__wrapper {
              &:first-child {
                border-radius: $default-border-radius $default-border-radius 0 0;
              }

              &:last-child {
                border-radius: 0 0 $default-border-radius $default-border-radius;
              }
            }
          }
        }
      }
    }

    .error-message {
      color: #ff4d4f;
      font-size: 14px;
      line-height: 1.5715;
    }

    .active-container {
      border: 1px solid $primary-color;
      position: relative;
    }

    .actions-controller__wrapper {
      // border: 1px solid $primary-color;
      position: relative;
    }
    .active-bouncing-arrow-inside-action-controller {
      border-bottom: 55px solid white;
    }
    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper),
    .active-actions-controller__wrapper {
      box-shadow: inset 0 0 0 2px #ddd;
      box-sizing: border-box;
    }

    .column-container {
      text-align: center;
      .icon-container {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: $primary-color;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        cursor: pointer;
        .container-plus-icon {
          color: #fff;
          font-size: 16px;
          margin-top: 11px;
        }
      }
    }
    .append-container-icon {
      display: none !important;
    }

    .column-wrapper {
      .circular-plus-button {
        width: 20px;
        height: 20px;
        bottom: -12px;

        svg {
          font-size: 10px;
        }
      }
    }
    .action-icons {
      display: none;
      position: absolute;
      z-index: 99;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        cursor: pointer;

        box-shadow: 0px -3px 8px rgb(0 0 0 / 8%);
        border-radius: 10px 10px 0 0;
        border: 1px solid $default-grey;

        li:hover {
          background-color: #fff;
        }

        li {
          width: 30px;
          height: 30px;
          text-align: center;
          display: inline-block;
          overflow: hidden;
          background-color: $light-grey;
          color: $primary-color;
          padding-top: 4px;
          border-right: 1px solid $default-grey;

          svg {
            vertical-align: middle;
          }

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-right: 0;
          }

          &.trash-icon {
            color: $light-grey;
            background-color: $dark-orange;

            &:hover {
              background-color: $orange;
            }
          }

          &.setting-icon {
            color: $primary-color !important;
            background-color: $light-grey !important;
          }

          &.setting-icon:hover {
            background-color: #fff !important;
          }
        }
      }
    }
    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper) > .action-icons,
    .active-actions-controller__wrapper > .action-icons {
      display: flex !important;
    }

    .actions-controller__wrapper:hover:not(.not-active-actions-controller__wrapper)
      > .append-container-icon,
    .active-actions-controller__wrapper > .append-container-icon {
      display: flex !important;
    }
    .acton-controller-main-wrapper {
      position: relative;
    }
    .acton-controller-main-wrapper:hover:not(.not-active-actions-controller__wrapper)
      > .append-container-icon,
    .active-actions-controller__wrapper > .append-container-icon {
      display: flex !important;
    }

    .action-position {
      &-bottom {
        right: 5px;
        top: 0;
      }

      &-top {
        right: 0;
        top: -31px;
      }
    }
  }

  &__settings-sidebar,
  &__icon-sidebar {
    border-right: 1px solid $default-grey;
    margin-top: 64px;
  }

  &__icon-sidebar {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;

    .sidebar-icons {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      &__submenu {
        width: 100%;

        > div {
          text-overflow: inherit;
        }

        .ant-menu-submenu-title {
          padding: 0 !important;
          height: initial !important;
          line-height: initial !important;
        }

        .sidebar-icons__icon {
          display: block;
        }

        .ant-menu-submenu-arrow {
          right: 10px;
          opacity: 0.2;
        }
      }

      &__icon {
        height: 1.75em;
        margin: 0.75em 0.25em;
        opacity: 0.2;
        color: $primary-color;

        svg {
          width: 100%;
          height: 100%;
        }

        &,
        &:hover {
          transition: ease opacity 0.5s;
        }

        &:hover,
        &-active {
          opacity: 1;
        }
      }
    }
  }

  &__settings-sidebar {
    flex: 0 0 435px !important;
    max-width: 435px !important;
    min-width: 435px !important;
    width: 435px !important;
    box-shadow: initial !important;
    border-bottom: 1px solid $default-grey;

    .menu-item {
      width: 100%;
      height: 55px;
      padding: 15px;
      display: flex;

      justify-content: space-between;

      font-size: 1.2em;
      font-weight: 200;

      border-bottom: 1px solid $default-grey;

      .anticon-right {
        color: $secondary-text-color;
      }
    }
  }
}

.ui__top-layer .ui__content {
  padding: 0;
  margin-top: 80px;
}

.block-col-style {
  background-color: $primary-color;
  height: 40px;
  border-radius: 5px;
  margin: 10px 2px;
}

.padding-left-10 {
  padding-left: 10px;
}

.error-border {
  border: 1px solid#ff4d4f;
  border-radius: 2px;
}

.circular-plus-button {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;

  svg {
    color: #fff;
    font-size: 12px;
  }
}

.add-button-green {
  cursor: pointer;
  background: $green;
  box-shadow: 0px 3px 6px 2px rgb(0 0 0 / 15%);
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;

  &,
  &:hover {
    transition: transform ease 0.5s;
  }

  &:hover {
    transform: scale(1.2);
  }

  svg {
    color: #fff;
    font-size: 10px;
  }

  &__with-label {
    font-size: 0.9em;
    padding: 0.5em 1em;

    &:hover {
      transform: scale(1.05);
    }

    svg {
      margin-right: 0.5em;
    }
  }
}
