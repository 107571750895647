$primary-main: #007bff;
$primary-dark: #0056b3;
$secondary-main: #6c757d;
$secondary-dark: #565e64;
$action-disabledBackground: #e0e0e0;
$text-disabled: #6c757d;
$color-text-secondary: #6c757d;
$color-grey-500: #9e9e9e;
$color-grey-900: #212121;
$border-radius-sm: 4px;
$transition-duration-short: 0.3s;
$divider: #ced4da;
$white: #ffffff;
$body2-font-size: 0.875rem;
$font-family-monospace: 'JetBrainsMono', monospace;
$font-family-serif: 'Georgia, serif';
$MARGIN: 0.75em;

.nml {
  padding: 0.25rem 0.75rem;
  margin: 1rem 0;
  width: 100%;
  display: block;

  & .MuiTypography-root {
    margin-top: 0;
  }
}

.editor-base {
  margin-bottom: 10px;
  width: 100%;

  & > .ant-space-item{
    width: 100%;
  }
}

.nml__editor__root {
  width: 100%;
  min-height: 150px;
  max-width: 600px;
  border-radius: $border-radius-sm;
  border: solid 1px rgba($color-grey-500, 0.2);
  scrollbar-width: thin;
  scrollbar-color: rgba($text-disabled, 0.4) rgba($text-disabled, 0.08);

  &.error {
    border: solid 1px #f44336;
  }

  &.disabled {
    opacity: 0.48;
    pointer-events: none;
  }

  &.fullScreen {
    top: 16px;
    left: 16px;
    position: fixed;
    z-index: 1300;
    max-height: unset !important;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    background-color: $white;
  }
}

.nml__editor__toolbar {
  &__hr,
  &__bold,
  &__code,
  &__undo,
  &__redo,
  &__link,
  &__clear,
  &__image,
  &__italic,
  &__strike,
  &__hardbreak,
  &__unsetlink,
  &__code__block,
  &__align__left,
  &__fullscreen,
  &__blockquote,
  &__bullet__list,
  &__align__right,
  &__ordered__list,
  &__align__center,
  &__align__justify {
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: background-color $transition-duration-short ease-in-out;

    &:hover {
      background-color: rgba($primary-main, 0.1);
    }
  }

  &__unsetlink {
    margin-left: 0.5rem;
  }

  &__root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: $secondary-main;
    padding: 0.75rem;
    border-bottom: solid 1px $divider;

    background-color: #fff;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    
    .color-picker {
      &__overlay {
        width: calc(100vw - 800px) !important;
      }
    }

    .toolbar-item-button {
      border-radius: 4px;
      font-size: 14px;
      padding: 4px;
    }

    .ant-space-item {
      margin-right: 0px !important;
      margin: 2px !important;
    }
  }
}

.nml__editor__content {
  &__root {
    width: 100%;
    min-height: 350px;
    max-height: 600px;
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    flex-direction: column;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: rgba($color-grey-500, 0.08);

    & .nml__editor__content__placeholder {
      &:first-of-type::before {
        font-family: inherit;
        font-size: inherit;
        color: $text-disabled;
        content: '';
      }
    }

    & .tiptap > * + * {
      margin-top: 0;
    }

    & .tiptap.ProseMirror {
      flex: 1 1 auto;
      outline: none;
      padding: 10px 16px;

      & > h1:first-child {
        margin-top: 0.25rem !important;
      }  
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1rem !important;
      margin-bottom: 0.5rem !important;
      font-weight: 500;
    }
    
    h1 {
      font-size: 2rem;
    }

    & > h1:first-child {
      margin-top: 0.25rem !important;
    }

    h2 {
      font-size: 1.7rem;
    }

    h3 {
      font-size: 1.35rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }

    h6 {
      font-size: 0.875rem;
    }

    p {
      margin-bottom: 0.4rem !important;
      font-size: 1rem;
      line-height: 1.5;
    }

    strong {
      font-weight: 600;
      color: $color-grey-900
    }

    &__link {
      color: $primary-main;
      text-decoration: underline;
      display: flex;
    }

    &__hr {
      flex-shrink: 0;
      margin: 2em 0;
      border-width: 0;
      border-bottom-width: thin;
      border-style: solid;
      border-color: $divider;
    }

    .nml__editor__content__bullet__list {
      padding-inline-start: 20px;
      list-style: disc;
    } 

    &__ordered__list {
      padding-left: 16px;
    }

    &__listItem {
      line-height: 2;
      margin-inline-start: 20px !important;
      & p {
        display: inline-block;
      }
    }

    &__blockquote {
      line-height: 1.5;
      font-size: 1.5em;
      margin: 24px auto;
      position: relative;
      font-family: $font-family-serif;
      padding: 24px 24px 24px 64px;
      color: $color-text-secondary;
      border-left: solid 8px rgba($color-grey-500, 0.08);

      & p {
        margin: 0;
        font-size: inherit;
        font-family: inherit;
      }

      &::before {
        left: 16px;
        top: -8px;
        display: block;
        font-size: 3em;
        content: '\\201C';
        position: absolute;
        color: $text-disabled;
      }
    }
  }
}

.mnl-base{
  background-color: transparent;
  min-height: fit-content;
  max-height: fit-content;
}

.mnl {
  margin: 1rem;
}

.mll {
  margin-left: 1rem;
}

.mrl {
  margin-right: 1rem;
}

.mtl {
  margin-top: 1rem;
}

.mbl {
  margin-bottom: 1rem;
}

.alignc {
  text-align: center;
}

.alignr {
  text-align: right;
}

.alignl {
  text-align: left;
}

.fullWidth {
  width: 100%;
}

.btn-primary {
  background-color: $primary-main;
  color: $white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: $border-radius-sm;
  cursor: pointer;
  transition: background-color $transition-duration-short ease-in-out;

  &:hover {
    background-color: $primary-dark;
  }

  &:disabled {
    background-color: $action-disabledBackground;
    color: $text-disabled;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: $secondary-main;
  color: $white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: $border-radius-sm;
  cursor: pointer;
  transition: background-color $transition-duration-short ease-in-out;

  &:hover {
    background-color: $secondary-dark;
  }

  &:disabled {
    background-color: $action-disabledBackground;
    color: $text-disabled;
    cursor: not-allowed;
  }
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  border-radius: $border-radius-sm;
  border: 1px solid $divider;
  transition: border-color $transition-duration-short ease-in-out;

  &:focus {
    border-color: $primary-main;
    outline: none;
  }

  &:disabled {
    background-color: $action-disabledBackground;
    color: $text-disabled;
  }
}
