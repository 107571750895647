/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.video-wrapper {
  background: #f9f9f9;
  aspect-ratio: 16/9;

  .disable-video-player {
    iframe {
      pointer-events: none;
    }
  }

  &__inner {
    padding: 2px;
    position: relative;
    background-size: 100%;

    .spinner__wrapper {
      position: absolute;
      top: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgb(180, 200, 209);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: larger;
    }
  }

  &__disabled-wrapper {
    background: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: #fff !important;
    }

    p {
      color: #fff;
      text-align: center;
      max-width: 650px;
    }

    &__icon {
      font-size: 4em;
      margin-bottom: 0.1em;
      color: #fff;
    }

    .btn-primary {
      background-color: $green;
      border-color: $green;
    }
  }

  .container-md {
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

#react-player-wrapper {
  height: 100% !important;
  width: 100% !important;
}
