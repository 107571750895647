.ad-builder-preview {
    max-width: 800px;
    margin: 50px auto 50px;

    .hidden-container {
        display: flex;
        justify-content: center;
        padding: 10px;
        svg {
          color: $primary-color;
        }
    }

    [data-cy="root-container"] {
        & > .actions-controller__wrapper {
            margin-bottom: 3rem;
        }
    }

    .post-preview {
        background: #fff;
        padding-top: 30px !important;
        padding-bottom: 0 !important;
        box-shadow: 2px 4px 10px rgb(0 0 0 / 10%);
        border-radius: 12px;
        border: 1px solid #e9e9e9;
        flex-direction: column;
        & > .rich-text__wrapper {
            margin-bottom: 1.25em;
            margin-inline: 30px !important;
        }
        & > .profile-section {
            margin-inline: 30px !important;
        }

        .footer-wrapper {
            .rich-text {
                &__wrapper {
                    margin-top: 0.4em;
                    margin-bottom: 0.4em;
                }

                &__inner {
                    font-weight: 600;
                    margin-bottom: 0px;
                    font-size: 1.25em;
                    line-height: 1.25em;
                    padding-right: 1em;

                    .public-DraftStyleDefault-block {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .media-content-wrapper {
        width: 100%;
        height: 100%;

        .action-position {
             &-top { 
                top: -32px !important; 
            } 
        }
        
        &,
        .actions-controller__wrapper {
            margin-top: 0 !important;
            margin-bottom: 0 !important;

            .img-element__wrapper {
                padding: 0;

                img {
                    height: 700px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .content-wrapper {
            padding: 40px;
        }
    }

    .footer-wrapper {
    }

    .container__padding {
        &-s-top,
        &-s-bottom {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
